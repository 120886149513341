import {Helmet} from "react-helmet";

const HelmetHead = ({headParams}) => {
    return (
        <Helmet
            encodeSpecialCharacters={true}
            titleTemplate="%s | Manchetten - электронная музыка и фото"
            defaultTitle="Manchetten - электронная музыка и фото"
            defer={false}
        >
            <body className="root"/>
            <title itemProp="name" lang="ru">{headParams.title}</title>

            {/* multiple meta elements */}
            <meta name="description" content={headParams.description} />
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={headParams.url} />
            <meta property="og:title" content={headParams.title + " | Manchetten - электронная музыка и фото"} />
            <meta property="og:description" content={headParams.description} />
            <meta property="og:image" content={headParams.image} />

            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content={headParams.url} />
            <meta property="twitter:title" content={headParams.title + " | Manchetten - электронная музыка и фото"} />
            <meta property="twitter:description" content={headParams.description} />
            <meta property="twitter:image" content={headParams.image} />


            {/* multiple link elements */}
            <link rel="canonical" href={headParams.url} />
        </Helmet>
    )
}

export default HelmetHead;