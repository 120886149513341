import './PodcastPage.scss'
import MixList from "../MixList/MixList";
import HelmetHead from "../HelmetHead/HelmetHead";


const PodcastPage = () => {
    return (
        <div className="container-fluid">
            <HelmetHead headParams={{
                "title": "Подкаст Summer Breeze",
                "url": "https://manchetten.ru/podcast/",
                "description": "Ежемесячная порция cолнечной музыки",
                "image": "https://storage.yandexcloud.net/manchetten-site-photo-gallery/site/site_bg_podcast.jpeg"
            }}/>
            <section className="row hero-podcast text-center text-white star">
                <div className="podcast-title h-100 d-flex align-items-center justify-content-center flex-column ">
                    <h4>Feel the Summer</h4>
                    <h1>Summer Breeze Podcast</h1>
                    <h4>Ежемесячная порция<br/>Солнечной музыки</h4>
                </div>
            </section>
            <MixList categoryName="podcast"/>
        </div>
    )
}

export default PodcastPage;