import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../helpers';

// create slice

const name = 'mixes';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const mixesActions = { ...slice.actions, ...extraActions };
export const mixesReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        mixes: {},
        loading: false,
        error: null
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_URL}/api/mix`;

    return {
        getAllInCategory: getAllInCategory(),
    };

    function getAllInCategory() {
        return createAsyncThunk(
            `${name}/getAllInCategory`,
            async ({ categoryName, page }) => await fetchWrapper.get(`${baseUrl}?page=${page}&categoryAlias=${categoryName}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...getAllInCategory()
    };

    function getAllInCategory() {
        var { pending, fulfilled, rejected } = extraActions.getAllInCategory;
        return {
            [pending]: (state) => {
                state.loading = true ;
            },
            [fulfilled]: (state, action) => {
                state.mixes = (state.mixes.length > 0) ? state.mixes.concat(action.payload) : action.payload;
            },
            [rejected]: (state, action) => {
                state.error =  action.error;
            }
        };
    }
}