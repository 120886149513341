import './App.scss';
import Header from "./components/Header/Header";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import MainPage from "./components/MainPage/MainPage";
import Footer from "./components/Footer/Footer";
import Category from "./components/Category/Category";
import Mix from "./components/Mix/Mix";
import GalleryPage from "./components/GalleryPage/GalleryPage";
import PhotoGallery from "./components/PhotoGallery/PhotoGallery";
import PodcastPage from "./components/PodcastPage/PodcastPage";
import PageNotFound from "./components/PageNotFound/PageNotFound";

function App() {
    return (
        <div className="App">
            <Header/>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainPage/>}/>
                    <Route path="/gallery/:galleryName" element={<PhotoGallery />}/>
                    <Route path="/gallery" element={<GalleryPage/>}/>
                    <Route path="/mixes/:categoryName" element={<Category />} />
                    <Route path="/mixes/:categoryName/:mixName" element={<Mix />} />
                    <Route path="/podcast" element={<PodcastPage />} />
                    <Route path="/podcast/:mixName" element={<Mix />} />
                    {/* 👇️ only match this when no other routes match */}
                    <Route path="*" element={<PageNotFound />} status={404} />
                </Routes>
            </BrowserRouter>
            <Footer/>
        </div>
    );
}

export default App;
