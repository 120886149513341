import './AboutText.scss'
import {ABOUT_ME} from '../../../constants/staticTexts';
import parse from 'html-react-parser';

const AboutText = () => {
    return (
        <section className="aboutSection">
            <div className="container">
                <div className="row justify-content-center align-items-center pb-3">
                    <h2 className="pt-5 pb-4">{ABOUT_ME[0].title}</h2>
                    <div className="col col-10 text-start">{parse(ABOUT_ME[0].description.toString())}</div>
                </div>
            </div>
        </section>
    )
}

export default AboutText;