import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from "react-router-dom";
import {galleryActions} from "../../store";
import './PhotoGallery.scss'
import Masonry from "react-masonry-css";
import FsLightbox from 'fslightbox-react';
import Moment from "moment";
import 'moment/locale/ru';
import HelmetHead from "../HelmetHead/HelmetHead";

const PhotoGallery = () => {
    let urlParams = useParams();

    const dispatch = useDispatch();
    const galleryAlias = urlParams.galleryName;
    const {gallery} = useSelector(x => x.gallery);
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    useEffect(() => {
        dispatch(galleryActions.getGallery({galleryAlias}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container-fluid">
            {!!gallery &&
                <section className="row gallery-header"
                         style={{backgroundImage: `url("${gallery.cover}")`}}>

                    <HelmetHead headParams={{
                        "title": "Съемка " + gallery.title,
                        "url": "https://manchetten.ru/gallery/" + gallery.alias,
                        "description": gallery.description,
                        "image": gallery.cover_thumb
                    }}/>

                    <div className="cover d-flex justify-content-start align-items-center">
                        <div className="col col-sm-4 col-md-4 col-xl-6 col-lg-6 text-start text-white ps-2">
                            <h6>{Moment(gallery.shoot_date).locale('ru').format('LL')}</h6>
                            <h1 className="my-3">{gallery.title}</h1>

                            <p>{gallery.description}</p>
                        </div>
                    </div>
                </section>
            }
            {!!gallery.images &&
                <Masonry
                    breakpointCols={{
                        default: 4,
                        1100: 3,
                        700: 2,
                        500: 1
                    }}
                    className="row photos-masonry-grid my-4"
                    columnClassName="col px-2"
                >
                    {gallery.images.map((image, index) =>
                        <div key={index} className="photo-item py-2" onClick={() => openLightboxOnSlide(index + 1)}>
                            <img
                                width="100%"
                                src={image.big_thumb}
                                className="attachment-album-thumbnail"
                                alt={"image_" + index}
                            />
                        </div>
                    )}
                </Masonry>
            }
            {!!gallery.images && <FsLightbox
                toggler={lightboxController.toggler}
                type="image"
                slide={lightboxController.slide}
                sources={gallery.images.map((image, index) => (image.big))}
            />}
        </div>
    )
}

export default PhotoGallery;