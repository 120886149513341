import {Helmet} from "react-helmet";
import './PageNotFound.scss'

const PageNotFound = () => {
    return (
        <section className="container-fluid">
            <Helmet
                encodeSpecialCharacters={true}
                titleTemplate="%s | Manchetten - электронная музыка и фото"
                defaultTitle="Страница не найдена | Manchetten - электронная музыка и фото"
                defer={false}
            >
                <meta name="title" content={"Страница не найдена | Manchetten - электронная музыка и фото"}/>
                <meta property="prerender-status-code" content="404"/>
            </Helmet>

                <div className="row text-center text-white notFoundPage">
                    <div className="h-100 d-flex align-items-center justify-content-center flex-column ">
                        <h1>404 | Страница не найдена</h1>
                    </div>
                </div>
        </section>
    )
}

export default PageNotFound;