import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from "react-router-dom";
import './Mix.scss';
import {mixActions} from "../../store";
import parse from 'html-react-parser';
import HelmetHead from "../HelmetHead/HelmetHead";

const Mix = () => {
    let urlParams = useParams();

    const dispatch = useDispatch();
    const mixAlias = urlParams.mixName;
    const categoryName = urlParams.categoryName;
    const {mix} = useSelector(x => x.mix);

    useEffect(() => {
        dispatch(mixActions.getOneMix({mixAlias}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="mixPage">
            {!!mix.cover_colours &&
                <section className="container-fluid">
                    <div className="row justify-content-center align-items-center mix-header"
                         style={{background: `linear-gradient(135deg, ${mix.cover_colours[0]} 0%, ${mix.cover_colours[1]} 50%, ${mix.cover_colours[2]} 100%)`}}>
                        <div className="col col-md-5 col-lg-5 col-xl-5 col-sm-8">
                            <img className="img-fluid rounded mx-auto d-block"
                                 src={mix.cover} alt=""/>
                        </div>
                    </div>
                </section>
            }
            {!!mix &&
                <section className="container">
                    <div className="row mix-description">
                        <HelmetHead headParams={{
                            "title": "Микс " + mix.title,
                            "url": "https://manchetten.ru/" + ((mix.type === 2) ? "podcast/" : "mixes/" + categoryName + "/") + mix.alias,
                            "description": mix.description,
                            "image": mix.cover
                        }}/>
                        <div className="sectionTitle pt-5 pb-2">
                            <h1>{mix.title}<span className="heading-b3 mt-3"/></h1>
                        </div>
                        <div className="col-12 py-4">
                            {mix.description}
                        </div>
                        <div className="col-12 py-4 pdj_player">
                            {parse(String(mix.player_code))}
                        </div>
                        <div className="col-12 mb-4">
                            <table className="table tracklist">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th className="text-start" scope="col">Artist - Title</th>
                                </tr>
                                </thead>
                                <tbody>
                                {!!mix.tracklist && Object.entries(mix.tracklist).map((track, index) =>
                                    <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td className="text-start">{track[0]} - {track[1]}</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            }
        </section>
    )
}

export default Mix;