import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../helpers';

// create slice

const name = 'mix';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const mixActions = { ...slice.actions, ...extraActions };
export const mixReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        mix: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_URL}/api/mix`;

    return {
        getOneMix: getOneMix(),
    };

    function getOneMix() {
        return createAsyncThunk(
            `${name}/getOneMix`,
            async ({ mixAlias }) => await fetchWrapper.get(`${baseUrl}?mixAlias=${mixAlias}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...getOneMix()
    };

    function getOneMix() {
        var { pending, fulfilled, rejected } = extraActions.getOneMix;
        return {
            [pending]: (state) => {
                state.mix = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.mix = action.payload;
                state.mix.cover_colours = JSON.parse(action.payload.cover_colours);
            },
            [rejected]: (state, action) => {
                state.mix = { error: action.error };
            }
        };
    }
}