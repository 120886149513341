import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {galleryCategoriesActions} from "../../store";
import './GalleryPage.scss'
import Moment from "moment";
import HelmetHead from "../HelmetHead/HelmetHead";
import {PHOTO_MAIN_PAGE} from '../../constants/staticTexts';
import parse from "html-react-parser";

const GalleryPage = () => {
    const dispatch = useDispatch();
    const {galleryCategories} = useSelector(x => x.galleryCategories);

    useEffect(() => {
        dispatch(galleryCategoriesActions.getGalleryCategoriesInfo());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="galleryPage">
            <HelmetHead headParams={{
                "title": "Портфолио фотографий",
                "url": "https://manchetten.ru/gallery",
                "description": "Фотография - мое давнее хобби. За несколько лет накопилось внушительное количество снимков,\n" +
                    " многие из которых достойны не только пылиться на жетском диске. С недавних пор, я стал\n" +
                    " снимать по-настоящему много и профессионально.",
                "image": "https://storage.yandexcloud.net/manchetten-site-photo-gallery/gallery_header_1920.jpeg"
            }}/>
            <section className="container-fluid">
                <div className="row photo-about">
                    <div className="cover d-flex justify-content-start align-items-center">
                        <div className="col col-sm-5 col-md-5 col-xl-5 col-lg-5 text-start text-white ps-2">
                            <h1 className="my-3">{PHOTO_MAIN_PAGE[0].title}</h1>
                            {parse(PHOTO_MAIN_PAGE[0].description.toString())}
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid">
                <div id="post-list"
                     className="row gallery-list py-4">
                    {galleryCategories.length && galleryCategories.map((gallery, index) =>
                        <div key={index}
                             className="py-2 col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3 gallery-item">
                            <a href={'/gallery/' + gallery.alias}>
                                <div className="album-icon">
                                    <img
                                        width="100%"
                                        src={gallery.cover_thumb}
                                        className="attachment-album-thumbnail"
                                        alt={gallery.title}
                                    />
                                </div>
                                <div className="name">
                                    <h3>{gallery.title}</h3>
                                    <p>{Moment(gallery.shoot_date).format('DD-MM-YYYY')}</p>
                                </div>
                            </a>
                        </div>
                    )}
                </div>
            </section>
        </section>
    )
}

export default GalleryPage;