import Hero from "./Hero/Hero";
import AboutText from "./AboutText/AboutText";
import RecsBlock from "./RecsBlock/RecsBlock";
import HelmetHead from "../HelmetHead/HelmetHead";

const MainPage = () => {

    return (
        <section className="mainPage">
            <HelmetHead headParams={{
                "title": "Главная",
                "url": "https://manchetten.ru/",
                "description": "Manchetten — диджей, музыкант и неисправимый романтик, совмещающий в своем творчестве самое лучшее из множества стилей электронной музыки.",
                "image": "https://storage.yandexcloud.net/manchetten-site-photo-gallery/site/bg_2.jpeg"
            }}/>
            <Hero/>
            <AboutText/>
            <RecsBlock recType="mix" />
            <RecsBlock recType="photo" />
        </section>
    )
}

export default MainPage;