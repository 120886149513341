import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../helpers';

// create slice

const name = 'gallery';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const galleryActions = { ...slice.actions, ...extraActions };
export const galleryReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        gallery: {}
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_URL}/api/`;

    return {
        getGallery: getGallery()
    };

    function getGallery() {
        return createAsyncThunk(
            `${name}/getGallery`,
            async ({galleryAlias}) => await fetchWrapper.get(`${baseUrl}gallery?galleryAlias=${galleryAlias}`)
        );
    }
}

function createExtraReducers() {
    return {
        ...getGallery()
    };

    function getGallery() {
        var { pending, fulfilled, rejected } = extraActions.getGallery;
        return {
            [pending]: (state) => {
                state.gallery = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.gallery = action.payload;
            },
            [rejected]: (state, action) => {
                state.gallery = { error: action.error };
            }
        };
    }
}