import Moment from "moment";
import React, {useEffect, useState} from "react";
import {fetchWrapper} from "../../../helpers";
import "./RecsBlock.scss"

const RecsBlock = ({recType}) => {
    const baseUrl = `${process.env.REACT_APP_URL}/api/`;
    const [recs, setRecs] = useState([])

    useEffect(() => {
        const uri = (recType === 'mix') ? baseUrl + 'mix-recommendation' : baseUrl + 'photo-recommendation'
        const fetchData = async () => {
            const result = await fetchWrapper.get(`${uri}?count=4`)
            setRecs(result)
        }
        fetchData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <section className="reccomenadionBlock mt-3">
            <div className="container-fluid">
                <h4>{(recType === 'mix') ? 'Послушать:' : 'Посмотреть:'}</h4>
                <section className="row recs-list py-4">
                    {!!recs && recs.map((rec, index) =>
                        <div key={index} className="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 rec-item">
                            <a href={(recType === 'mix') ? ((rec.type === 2) ? '/podcast/' : 'mixes/') + rec.alias : '/gallery/' + rec.alias}>
                                <div className="album-icon">
                                    <img
                                        width="100%"
                                        src={rec.cover}
                                        className="attachment-album-thumbnail"
                                        alt={rec.title}
                                    />
                                </div>
                                <div className="name">
                                    <h3>{rec.title}</h3>
                                    <p>{Moment((recType === 'mix') ? rec.recorded_at : rec.shoot_date).format('DD-MM-YYYY')}</p>
                                </div>
                            </a>
                        </div>
                    )}
                </section>
            </div>
        </section>
    )
}

export default RecsBlock;