export const ABOUT_ME = [
    {
        title: 'About Me',
        description: [
            `<p>
Привет!
Меня зовут Сергей и этот ресурс посвящен мои творческим увлечениям, которые давно уже стали больше, чем просто хобби.
Вообще, по основной профессии я ITшник, но стремление развиваться в различных направлениях открывает все новые границы себя.
Я неисправимый романтик, много путешествую, и ищу гармонию и нотки прекрасного практически везде, где нахожусь. И обычно, все найденное тут же находит свое отражение в музыкальных миксах или фотографии.
</p>
<p>
Моя особенная страсть - это электронная музыка. Все началось еще в далеком 2007 году с Drum’n’Bass музыки. Через некоторое время стало понятно, что быть простым слушателем уже не получается. И именно тогда начались первые эксперименты со сведением треков. При сведении ставилась задача не просто соединить любимые композиции, но придать настроение всему миксу, вложить туда частичку души. Самой удавшейся работой того периода считаю микс Connect to the Matrix — удалось полностью подчинить все треки единому настроению и атмосфере так, как будто это единое целое.
</p>
Случайно услышанный выпуск A State of Trance в 2008 году резко изменил дальнейший путь в творчестве. Стиль trance оказался настоящим выражением чувств и эмоций и просто неисчерпаемым на эксперименты. А после знакомства с Roger Shah и лейблом Magic Island, был окончательно определен «свой» стиль — солнечная и теплая музыка, вызывающая улыбку и состояние полета.
</p>
<p>
На этом музыкальные путешествия не закончились. С недавнего времени практически стерлись границы между стилями. Начался настоящий серфинг по волнам электронной музыки, да и не только. Сейчас я записываю миксы, сочетающие в себе сразу несколько стилей — atmospheric breaks и progressive, заводной funky house и более вдумчивый progressive house. Особое место занял стиль chillout и deep house — мелодичные и красивые треки, наполненные солнцем и эмоциями.
</p>
<p>
Что касается фотографии, то тут тоже оказался свой интересный путь. Долгое время, я снимал просто для себя, семью и друзей, а также, все, что видел в путешествиях.
Постепенно, рос уровень, улучшалась техника. Я начал снимать тематические съемки, прошел несколько мастерклассов и курсов.
Сейчас я делаю коммерческие съемки и всегда рад реализовать интересные идеи. Мое портфолио можно посмотреть в разделе галереи.
</p>`,
        ]
    },
]

export const PHOTO_MAIN_PAGE = [
    {
        title: 'Мир через объектив',
        description: [
            `<p>
                Фотография - мое давнее хобби. За несколько лет накопилось внушительное количество снимков, многие из которых достойны не только пылиться на жетском диске. С недавних пор, я стал снимать по-настоящему много и профессионально.
                            </p>
                            <p>
                                У меня много любимых стилей, но больше всего люблю снимать женский портрет и тревел фото. 
                                Я постоянно нахожусь в поиске новых образов и творческих идей. 
                                Также, провожу коммерческие съемки - пишите в любую соцсеть, обсудим ваши будущие прекрасные снимки :)
                            </p>`
        ]
    }
]