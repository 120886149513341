import {useState} from 'react';
import {Button, Container, Fade, Nav, Navbar} from "react-bootstrap";
import './Header.scss';

const Header = () => {
    const [openMainMenu, setMainMenuOpen] = useState(false);
    const [openSubMenu, setSubMenuOpen] = useState(false);

    const handleSubMenuClick = event => {
        event.preventDefault();
        setSubMenuOpen(!openSubMenu)
    };

    window.addEventListener('scroll', function (e) {
        let header = document.getElementById('topHeader');
        if(header) {
            if (window.scrollY >= 100) {
                header.classList.remove('bg-transparent');
                header.classList.add('bg-black');
            } else {
                header.classList.remove('bg-black');
                header.classList.add('bg-transparent');
            }
        }
    });

    return (
        <>
            <Fade in={openMainMenu}>
                <div
                    className={`d-flex w-100 h-100 float-start position-fixed aligns-items-center justify-content-center menuFixed ${openMainMenu ? 'open' : ''}`}
                    id="menuFixedNav">
                    <Nav className="flex-column justify-content-center align-content-between">
                        <div className="x-filter mx-auto" onClick={() => setMainMenuOpen(!openMainMenu)}>
                            <span/>
                            <span/>
                        </div>
                        <ul className="">
                            <li className={openSubMenu ? 'no-hovered' : ''}>
                                <a href="/">Главная</a></li>
                            <li className={openSubMenu ? 'hover-sub-menu click' : 'hover-sub-menu'}>
                                <a href="/mixes" onClick={handleSubMenuClick}>Миксы</a>
                                <ul className="sub-menu align-self-start">
                                    <li>
                                        <a href="/mixes/sun-and-move">Sun &amp; Move</a></li>
                                    <li>
                                        <a href="/mixes/deep-and-love">Deep &amp; Love</a></li>
                                    <li>
                                        <a href="/mixes/deep-and-breaks">Deep &amp; Breaks</a></li>
                                    <li>
                                        <a href="/mixes/trance-we-love">Trance We Love</a></li>
                                </ul>
                            </li>
                            <li className={openSubMenu ? 'no-hovered' : ''}>
                                <a href="/podcast">Summer Breeze Podcast</a></li>
                            <li className={openSubMenu ? 'no-hovered' : ''}>
                                <a href="/gallery">Галерея</a></li>
                        </ul>
                        <div className="x-filter mx-auto" onClick={() => setMainMenuOpen(!openMainMenu)}>
                            <span/>
                            <span/>
                        </div>
                    </Nav>
                </div>
            </Fade>
            <Navbar bg="transparent" expand="true" fixed="top" id="topHeader">
                <div className="container-fluid px-4">
                    <Navbar.Brand href="/" className="logoText text-white">Manchetten</Navbar.Brand>
                    <Button
                        className="custom-toggler navbar-toggler text-white"
                        onClick={() => setMainMenuOpen(!openMainMenu)}
                        aria-controls="menuFixedNav"
                        aria-expanded={openMainMenu}>
                        <span className="navbar-toggler-icon text-white"/>
                    </Button>
                </div>
            </Navbar>
        </>
    )
}

export default Header;