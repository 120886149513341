import React, {useEffect} from 'react';
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {categoriesActions} from "../../store";
import './Category.scss'
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from "react-loading-skeleton";
import MixList from "../MixList/MixList";
import HelmetHead from "../HelmetHead/HelmetHead";

const Category = () => {
    let urlParams = useParams();

    const dispatch = useDispatch();
    const categoryName = urlParams.categoryName;
    const {categories} = useSelector(x => x.categories);

    useEffect(() => {
        dispatch(categoriesActions.getCategoryInfo({categoryName}));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container-fluid">
            {categories.loading ?
                <Skeleton style={{height: '90vh'}} className="row category-about"/>
                :
                <section className="row category-about justify-content-start align-items-center"
                         style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/mixes_categories/' + categories.image_name})`}}>
                    <HelmetHead headParams={{
                        "title": "Миксы в стиле " + categories.name,
                        "url": "https://manchetten.ru/mixes/" + categories.slug,
                        "description": categories.description,
                        "image": process.env.PUBLIC_URL + '/mixes_categories/' + categories.image_name
                    }}/>
                    <div
                        className="col col-sm-4 col-md-4 col-xl-5 col-lg-5 text-start text-white">
                        <h1>{categories.name}</h1>
                        <p>{categories.description}</p>
                    </div>
                </section>
            }

            <MixList categoryName={categoryName} />
        </div>
    )
}

export default Category;