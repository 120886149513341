import { configureStore } from '@reduxjs/toolkit';

import { mixesReducer } from './mixes.slice';
import { mixReducer } from './mix.slice';
import { categoriesReducer } from './categories.slice';
import { galleryCategoriesReducer } from './gallery-categories.slice';
import { galleryReducer } from './gallery.slice';

export * from './mix.slice';
export * from './mixes.slice';
export * from './categories.slice';
export * from './gallery-categories.slice';
export * from './gallery.slice';

export const store = configureStore({
    reducer: {
        mixes: mixesReducer,
        mix: mixReducer,
        categories: categoriesReducer,
        galleryCategories: galleryCategoriesReducer,
        gallery: galleryReducer,
    },
});