import './Footer.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faVk, faApple, faSoundcloud, faInstagram, faTelegram} from '@fortawesome/free-brands-svg-icons'

const Footer = () => {

    return (
        <section className="footerSection pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="copyFooter">
                                <a href="#">© Manchetten 2014 - 2022</a>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <nav className="social-icons">
                                <ul className="clearfix">
                                    <li>
                                        <a href="https://instagram.com/manchetten" className="icon-button shopIcon"
                                           target="_blank" rel="noreferrer" >
                                            <FontAwesomeIcon icon={faInstagram} /><span/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://t.me/manchetten" className="icon-button shopIcon"
                                           target="_blank" rel="noreferrer" >
                                            <FontAwesomeIcon icon={faTelegram} /><span/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://vk.com/manchetten" className="icon-button shopIcon"
                                           target="_blank" rel="noreferrer" >
                                            <FontAwesomeIcon icon={faVk} /><span/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://itunes.apple.com/ru/podcast/summer-breeze/id900522315"
                                           className="icon-button shopIcon" target="_blank" rel="noreferrer" >
                                            <FontAwesomeIcon icon={faApple} /><span/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://soundcloud.com/manchetten" className="icon-button shopIcon"
                                           target="_blank" rel="noreferrer" >
                                            <FontAwesomeIcon icon={faSoundcloud} /><span/>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-sm-4">
                            <div className="goTop back-to-top" id="back-to-top">
                                <i className="fa fa-angle-up"/>
                                <a href="#">Go Top</a>
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    )
}

export default Footer;