import Moment from "moment";
import 'react-loading-skeleton/dist/skeleton.css';
import Skeleton from "react-loading-skeleton";
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {mixesActions} from "../../store";
import './MixList.scss';

const MixList = ({categoryName}) => {

    const {mixes} = useSelector(x => x.mixes);
    const loading = useSelector((state) => state.mixes.loading);
    const error = useSelector((state) => state.mixes.error);
    const dispatch = useDispatch();
    const [pageNum, setPageNum] = useState(1);

    // infinite scrolling with intersection observer
    const useInfiniteScroll = (scrollRef, dispatch) => {
        const observer = useMemo(() => new IntersectionObserver(entries => {
            entries.forEach(en => {
                if (en.intersectionRatio > 0) {
                    setPageNum(old => old + 1);
                }
            });
        }), []);

        useEffect(() => {
            if (scrollRef.current) {
                observer.observe(scrollRef.current);
            }

            return () => observer.disconnect();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [observer]);

        useEffect(() => {
            dispatch(mixesActions.getAllInCategory({categoryName: categoryName, page: pageNum}));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [pageNum]);
    }

    let bottomBoundaryRef = useRef(null);
    useInfiniteScroll(bottomBoundaryRef, dispatch);

    // useEffect(() => {
    //     dispatch(mixesActions.getAllInCategory({categoryName: categoryName, 'page': 1}));
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <div id="podcast-list">
            <section
                className="row mix-list py-4">
                {mixes.length && mixes.map((mix, index) =>
                    <div key={index} className="col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mix-item">
                        <div className="album-icon">
							<span className="thumbs-album">
                                <a href={((mix.type === 2) ? '/podcast/' : `/mixes/${categoryName}/`) + mix.alias}>
                                    <img
                                        width="100%"
                                        src={mix.cover}
                                        className="attachment-album-thumbnail wp-post-image"
                                        alt={mix.title}
                                    />
                                </a>
                                </span>
                        </div>
                        <div className="name">
                            <h3>{mix.title}</h3>
                            <p>{Moment(mix.recorded_at).format('DD-MM-YYYY')}</p>
                        </div>
                    </div>
                )}
                {!loading &&
                    <div className="col col-xs-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mix-item">
                        <Skeleton height={80}/>
                        <Skeleton count={2}/>
                    </div>
                }
                {error && <div className="text-danger">Error loading users: {error.message}</div>}

            </section>
            {mixes.length >= 8 && <div id='page-bottom-boundary' ref={bottomBoundaryRef}/>}
        </div>
    )
}

export default MixList;